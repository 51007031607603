<template>
  <div>
    <b>Create new mentor</b>
  </div>
</template>

<script>
import {

} from 'bootstrap-vue'

export default {
  components: {

  },
  data() {
    return {

    }
  },
}
</script>